#subcategories {
  margin-bottom: 20px;

  .subcategory-heading {
    margin-bottom: 20px;
    font-weight: 500;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

    li {
      //width: calc(100% / 4 - 20px);
      min-width: 200px;
      margin: 10px;
      text-align: center;

      transition: transform .3s,box-shadow .3s,background-color .3s,color .3s,opacity .3s;

      .subcategory-image {
        padding: 0 0 8px;

        a {
          display: block;
          //padding: 9px;
          //border: 1px solid $gray-light;

          img {
            height: 278px;
            width: 292px;
            
            max-width: 100%;
            vertical-align: top;
          }
        }
      }

      .subcategory-name {
        font-family: 'Botanika Mono', sans-serif;
        text-transform: uppercase;
        color: #000;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-left: 0.2rem;
      }

      .cat_desc {
        display: none;
      }

      &:hover {
        /* box-shadow: 0 3px 6px -4px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23); */
        outline: 1px solid #999;
        .subcategory-image {
          a {
            //padding: 5px;
            //border: 5px solid $brand-primary;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #subcategories {
    ul {
      justify-content: center;
    }
  }
}
