@import 'header/navbar';
@import 'header/backToTop';
@import 'header/dropdowns';
@import 'header/phone';
@import 'header/search';
@import 'header/mobile';

#header {
	--background: #f4f4f4;
	--color: #000;
	--logo: #{$brand-primary};

	background: var(--background) !important;
	color: var(--color) !important;
	position: relative;
	z-index: 999;
	//box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);

	svg:not(.hamburger-svg) {
		path {
			fill: currentColor;
		}
	}

	a {
		color: var(--color);

		&:hover {
			color: $brand-primary;
			text-decoration: none;
		}
	}

	.header-spacer {
		background-color: var(--color);
		height: 2px;
		width: 100%;
	}

	// Top row
	.header-nav {
		align-items: center;
		display: flex;
		height: 5rem;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 80dvw;
		width: 100%;

		@media screen and (max-width: 768px) {
			align-items: center;
			flex-direction: column;
			height: 6.2rem;
		}

		.logo-wrapper {
			svg {
				height: 3rem;
				max-width: 210px;
				object-fit: cover;
				width: 100%;

				path {
					fill: var(--logo);
				}
			}

			@media screen and (max-width: 768px) {
				display: none;
			}
		}

		.logo-link {
			path {
				fill: var(--logo);
			}
		}

		.header-search-wrapper {
			padding-top: 1rem;

			> div:has(input:invalid:focus) {
				outline: 1px solid red;
			}

			form {
				align-items: center;
				border-bottom: 1px solid var(--color);
				display: flex;
				justify-content: space-between;

				@media screen and (max-width: 768px) {
					border-bottom: none;
				}
			}

			input {
				background: none;
				border: none;
				color: var(--color);
				min-width: 262px;
				outline: none;
				padding: 12px 24px;

				&::placeholder {
					color: hsla(0, 0%, 0%, 0.644);
				}

				&:focus {
					border: none !important;
				}
			}

			button {
				background: none;
				border: none;
				cursor: pointer;
			}

			@media screen and (max-width: 768px) {
				padding-top: 0;
			}
		}

		> .icons-wrapper {
			align-items: center;
			display: flex;
			gap: 0.25rem;
			justify-content: center;
			padding-top: 1rem;

			a {
				padding: 0.5rem;
			}

			@media screen and (max-width: 768px) {
				display: none;
			}
		}

		#menu-icon {
			cursor: pointer;
			margin: 0 1rem;
			vertical-align: middle;

			.material-icons {
				line-height: 50px;
			}
		}

		.right-nav {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}

		.currency-selector {
			margin-left: 0.9375rem;
			margin-top: 0.9375rem;
			white-space: nowrap;
		}

		.user-info {
			margin-left: 2.5rem;
			margin-top: 0.9375rem;
			text-align: right;
			white-space: nowrap;

			.account {
				margin-left: $small-space;
			}

			a {
				color: #262626 !important;
				display: block;
				font-weight: normal;

				line-height: 34px;
				padding-bottom: 0.625rem;
				width: 100%;

				&:hover {
					color: hsla(0, 0%, 7%, .85) !important;
				}

				span {
					color: #5e5e5e !important;
					font-weight: 700;
					line-height: 34px;

					&:hover {
						color: hsla(0, 0%, 7%, .85) !important;
					}
				}
			}
		}

		.language-selector {
			margin-top: 0.9375rem;
			white-space: nowrap;
		}

		.cart-preview {

			.shopping-cart {
				color: $gray;
				vertical-align: middle;
			}

			.body {
				display: none;
			}

			.header {
				color: #5e5e5e !important;
				font-weight: 700;
				line-height: 34px;

				&:hover {
					color: #456d44 !important;
				}
			}
		}

		#_desktop_contact_link {
			display: inline-block;

			#contact-link {
				margin-top: 0.9375rem;
			}
		}

		.search-widget {
			margin-top: 0.2rem;
		}

		.material-icons {
			line-height: inherit;

			&.expand-more {
				margin-left: -0.375rem;
			}
		}
	}

	// Second row (navbar)
	.header-top {
		width: 100%;

		.header-categories {
			display: flex;
			width: 100%;
		}

		.header-content {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.header-content-links {
				display: flex;
			}
		}

		.header-categories {
			> .navbar {
				display: flex;
				justify-content: space-between;
				width: 100%;

			}
		}

		.navbar-container {
			width: 100%;
		}

		.navbar {
			background-color: var(--background);
			color: var(--color);
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			padding: 0;

			&::after {
				content: none;
				display: none !important;
			}

			.navbar-item {
				color: var(--color);
				font-size: 16px;
				line-height: 34px;
				padding: 14px 16px;
				transition: background-color .2s;
				white-space: nowrap;

				&:hover {
					background-color: hsla(0, 0%, 0%, 0.1);
				}
			}

			.hamburger-menu {
				align-items: center;
				cursor: pointer;
				display: flex;
				margin-right: 14px;

				.hamburger-icon {
					height: 35px;
					width: 35px;

					svg {
						fill: var(--color);
						height: 35px;
						width: 35px;
					}
				}
			}
		}
	}

	.cart-icon {
		display: inline-block;

		strong {
			border: 2px solid #262626;
			border-radius: 0;
			color: #262626;
			display: inline-block;
			font-family: Helvetica, Arial, sans-serif;
			font-size: 1em;
			font-weight: 700;
			height: 2.2em;
			line-height: 1.9em;
			margin: .3em 0 .3em 10px;
			position: relative;
			text-align: center;
			vertical-align: middle;
			width: 2.2em;

			&:after {
				border: 2px solid #262626;
				border-bottom: 0;
				border-top-left-radius: 99px;
				border-top-right-radius: 99px;
				bottom: 100%;
				content: ' ';
				height: 8px;
				left: 50%;
				margin-bottom: 0;
				margin-left: -7px;
				pointer-events: none;
				position: absolute;
				transition: height .1s ease-out;
				width: 14px
			}
		}

		&:hover {
			strong {
				border-color: hsla(0, 0%, 49%, 0.85);
				color: hsla(0, 0%, 49%, 0.85);
				cursor: pointer;

				&:after {
					border-color: hsla(0, 0%, 49%, 0.85);
					height: 10px;
				}
			}
		}
	}

	.user-info {
		align-items: center;
		display: flex;
		padding: 0 0.625rem;

		.divider {
			border-left: 1px solid rgba(0, 0, 0, .1);
			height: 30px;
			margin: 0 7.5px;
			position: relative;
			vertical-align: middle;
		}

		a {
			color: #262626;
			display: block;
			font-weight: normal;
			line-height: 34px;
			width: 100%;

			&:hover {
				color: hsla(0, 0%, 49%, 0.85) !important;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.logo {
		height: unset !important;
		width: unset !important;
	}
	.top-logo {
		align-items: center;
		display: flex;
		min-height: 50px;

		img {
			max-height: 2rem;
			width: auto;
		}

		> h1 {
			margin: 0;
		}
	}
}

#_desktop_user_info {
	white-space: nowrap;
}

#_desktop_cart {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-left: 1rem;
	position: relative;
	white-space: nowrap;

	.blockcart {
		.header {
			span {
				color: #262626 !important;
				font-weight: normal;
				line-height: 50px;
				text-decoration: none;

				&:hover {
					color: hsla(0, 0%, 49%, 0.85) !important;
				}
			}
		}
	}
}

// Main nav
.header-container {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	gap: 0 1.5rem;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 80dvw;
	width: 100%;

	&.category-header-container {
		max-width: calc(80dvw + 32px);
	}
}

// Blog | User info | cart
.header-submenu {
	align-items: center;
	background: white;

	color: #262626;
	display: flex;
	justify-content: space-between;

	.one-row {
		align-items: center;
		display: flex;
		flex-direction: row;

	}
}
