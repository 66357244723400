#footer {
  padding-top: 3rem;
}

#blockEmailSubscription_displayFooterBefore {
  float: inherit;
  width: 100%;
  margin: auto;
}
#submenu {
  display: none!important;
}

.cms-page-link, .custom-page-link {
  margin: 2.5rem 0;
  font-weight: 700;
  text-align: center;
  color: #5a5a5a!important;
  :hover{
    color: unset;
  }
}
.footer-form {
  padding-left: 0px!important;
}
.is-divider {
  background-color: rgba(0,0,0,.1);
  display: block;
  height: 3px;
  margin: 1em 0;
  margin-top: 1em;
  max-width: 30px;
  width: 100%;
}
.footer-heading {
  display: none!important;
}
.footer-text {
  margin-top: 0.5rem;
  font-weight: 700;
  color: #5a5a5a;
  font-size : .875rem;
}
.block-contact {
  font-size: 0.875rem;
  color: $gray;

  .block-contact-title {
    color: $gray-darker;
  }

  .navbar-toggler .material-icons {
    color: $gray-darker;
  }

  @include media-breakpoint-down(sm) {
    #contact-infos {
      padding: 0.625rem;
      padding-top: 0;
    }
  }
}
@media (max-width: 767px) {
  .is-divider{
    display: none;
  }
}
.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}
.btn-footer {
  background: #C8103F;
  color: white;
  width: 100%;
  margin-top: 5%;
}
.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-size: $base-font-size;
  font-weight: 700;
}

.block-social {
  text-align: right;

  ul {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0.125rem;
      cursor: pointer;
      //background-color: $gray-light;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        width: 2rem;
        height: 2rem;
        margin-right: 1.3rem;
        margin-bottom: 1.3rem;
        background-size: contain;
      }

      &:hover {
        background-color: $brand-primary;
      }

      a {
        display: block;
        height: 100%;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;

        &:hover {
          color: transparent;
        }
      }
    }
  }
}

.facebook {
  background-image: url("../img/facebook.svg");

  &::before {
    content: "";
    background-image: url("../img/facebook-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/facebook-gray.svg");

    &:hover {
      background-image: url("../img/facebook-blue.svg");
    }
  }
}

.twitter {
  background-image: url("../img/twitter.svg");

  &::before {
    content: "";
    background-image: url("../img/twitter-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/twitter-gray.svg");

    &:hover {
      background-image: url("../img/twitter-blue.svg");
    }
  }
}

.linkedin {
  background-image: url("../img/linkedin.svg");
  background-position: center center;
}

.rss {
  background-image: url("../img/rss.svg");
}

.youtube {
  background-image: url("../img/youtube.svg");
}

.googleplus {
  background-image: url("../img/gplus.svg");

  &::before {
    content: "";
    background-image: url("../img/gplus-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/gplus-gray.svg");

    &:hover {
      background-image: url("../img/gplus-blue.svg");
    }
  }
}

#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-darker;
  }
}

.pinterest {
  background-image: url("../img/pinterest.svg");

  &::before {
    content: "";
    background-image: url("../img/pinterest-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/pinterest-gray.svg");

    &:hover {
      background-image: url("../img/pinterest-blue.svg");
    }
  }
}

.vimeo {
  background-image: url("../img/vimeo.svg");
}

.instagram {
  background-image: url("../img/instagram.svg");
}

.footer-container {
  padding-top: $extra-large-space;
  padding-bottom: $medium-space;
  overflow: hidden;

  &__holder {
    display: flex;
    max-width: 80dvw;
    margin: 0 auto;
    justify-content: space-between;

    .links-flex {
      flex: 2 1 auto;
    }
  }

  .h3,
  .h4 {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  li {
    margin-bottom: 0.3125rem;
  }

  li a {
    font-size: $font-size-sm;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $brand-primary;
    }
  }
}

.links {
  .collapse {
    display: inherit;
  }
}
.newsletter-responsive {
  display: none;
}
@include media-breakpoint-down(sm) {
  .footer-text{
    color: #ABC2AB;
    text-align: center;
  }
  .newsletter-responsive {
    display: block;
  }
  .block-contact {
    display: none!important;
  }
  .block_newsletter {
    padding-bottom: 0.625rem;
    //border-bottom: 1px solid $gray-lighter;
  }
  #submenu {
    display: block!important;
  }
  .footer-newsletter-title {
    margin: 2.5rem 0;
    font-weight: 500;
    text-align: center;
    color: #456d44;
  }
  .is-divider{
    margin-left: 10px;
    max-width: 58px;
    background-color: #ABC2AB!important;
    .collapse {
      height: 1.5px;
      display: block;
    }
  }
  .links {
    .title {
      display: none !important;
    }
  }
  .footer-container {
    margin-top: 0;
    box-shadow: none;

    .container {
      max-width: 80dvw!important;
    }
      &__holder {
        max-width: unset;
        margin: 0 auto;
      }
    .wrapper {
      /* stylelint-disable */
      padding-right: 0 !important;
      padding-left: 0 !important;
      /* stylelint-enable */
    }

    .links {
      //display: block!important;
      >div {
        padding-top: 20px;
        padding-bottom: 20px;

        background-color: #4E734E;
      }
      .h3 {
        font-size: 1rem;
        line-height: 1.5;
        padding-left: 10px;
        color: $white;
        padding-top: 10px;
      }

      ul {
        margin-bottom: 0;
        //background-color: $gray-lighter;

        > li {
          padding: 0.625rem;
          font-weight: 600;

          a {
            color: $white!important;
          }
        }
      }
    }
  }
  //.footer-heading {
  //  display: block!important;
  //}
  .links {
    .collapse {
      display: block;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem;
      cursor: pointer;
      border-bottom: 1px solid $gray-lighter;

      .collapse-icons .remove {
        display: none;
      }
    }

    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }

  .block-contact {
    background-color: white;
    padding-left: 1.5rem;
  }
}

.email-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .input-wrapper {
    width: 100%;

    input {
      min-width: 0px !important;
      outline: none;
    }
  }

  .input-row {
    display: flex;
    gap: 0.5rem;
    width: 100%;
  }

  .btn {
    width: 100%;
    text-transform: none;
  }
}

.email-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .input-wrapper {
    width: 100%;

    input {
      min-width: 0px !important;
      outline: none;
    }
  }

  .input-row {
    display: flex;
    gap: 0.5rem;
    width: 100%;
  }

  .btn {
    width: 100%;
    text-transform: none;
  }
}
