.tooltip-phone {
	display: inline-block;
	position: relative;

	.tooltip-bottom {
		background-color: black;
		border-radius: 6px;
		bottom: -70%;
		color: #fff;
		left: 50%;
		margin-left: -85px;
		opacity: 0;
		padding: 5px;
		position: absolute;
		text-align: center;
		transition: opacity 0.3s;
		visibility: hidden;
		width: 170px;
		z-index: 1;
	}

	&:hover .tooltip-bottom {
		@media only screen and (min-width: 768px) {
			opacity: 1;
			visibility: visible;
		}
	}
}