#products {
  color: $gray;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .up {
    margin-bottom: 1rem;

    .btn-secondary {
      color: $gray;
      text-transform: inherit;

      .material-icons {
        margin-right: 0;
      }
    }
  }
}

.block-category {
  padding: 0;

  h1 {
    padding-top: 0;
    padding-left: 0;
    text-align: left;
    font-weight: 500;
    font-size: 27px;
    text-transform: initial;
  }

  .block-category-inner {
    display: flex;
    justify-content: space-between;

    #category-description {
      p,
      strong {
        font-weight: 400;
        color: $gray;
      }

      p {
        margin-bottom: 0;
        color: $gray-darker;
        word-break: break-word;

        &:first-child {
          margin-bottom: $medium-space;
        }
      }
    }

    .category-cover {
      img {
        width: 141px;
        height: 180px;
      }
    }
  }
}

.products-selection {
  .sort-by-row {
    display: flex;
    align-items: center;
  }

  .sort-by {
    margin-right: -0.9375rem;
    margin-left: 0.9375rem;
    text-align: right;
    word-break: break-word;
    white-space: normal;
  }

  .total-products {
    padding-top: 0.625rem;
  }

  h1 {
    padding-top: 0.625rem;
  }

  p,
  .sort-by-row {
    &,
    .sort-by,
    .select-title {
      font-size: 0.875rem;

      i {
        height: inherit;
        line-height: inherit;
      }
    }
  }

  .filter-button {
    .btn-secondary {
      padding: 0.75rem 0.5rem 0.6875rem;
    }
  }
}

.products-sort-order,
.brands-sort,
.suppliers-sort {
  color: $gray;

  .select-title {
    display: inline-block;
    width: 100%;
    padding: $small-space;
    color: $gray-darker;
    cursor: pointer;
    background: $white;
    border: $input-btn-border-width solid $input-border-color;
  }

  .select-list {
    display: block;
    padding: $small-space $medium-space;
    color: $gray-darker;

    &:hover {
      color: $white;
      text-decoration: none;
      background: $brand-primary;
    }
  }

  .dropdown-menu {
    left: auto;
    width: 16.88rem;
    margin: 0;
    background: $gray-lighter;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.facet-dropdown {
  box-sizing: border-box;
  padding-right: 0;
  padding-left: 0;
  color: $gray;
  background: $gray-light;
  border: 3px solid transparent;
  box-shadow: 1px 1px 1px 1px $gray-light;

  &.open {
    border: 0;

    > .select-title {
      background: $gray-lighter;
      border: 3px solid $brand-primary;
    }
  }

  .select-title {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: $small-space $small-space $small-space $medium-space;
    margin-left: 0;
    color: $gray-darker;
    cursor: pointer;
    background: $gray-light;

    > i {
      margin-left: auto;
    }
  }

  .select-list {
    display: block;
    padding: $small-space $medium-space;
    color: $gray-darker;
    background: $gray-lighter;

    &:hover {
      color: $white;
      text-decoration: none;
      background: $brand-primary;
    }
  }

  .dropdown-menu {
    left: auto;
    width: 100%;
    padding: 0;
    margin-top: 3px;
    background: $gray-lighter;
    border: none;
    box-shadow: 1px 1px 1px 1px $gray-light;
  }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  padding: 1.563rem 1.25rem;
  margin-bottom: 1.563rem;
  background: $white;

  > .h6 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .facet {
    padding-top: $small-space;

    .collapse {
      display: block;
    }

    .facet-title {
      font-size: 1rem;
      color: $gray-darker;
    }

    .facet-label {
      margin-bottom: 0;

      a {
        display: inline-block;
        margin-top: 0.4375rem;
        font-size: $font-size-lg;
        color: $gray-darker;
      }
    }
  }
}

#search_filters_brands,
#search_filters_suppliers {
  .facet {
    padding-top: 0;

    .facet-label {
      margin-bottom: 0.3125rem;
    }
  }
}

.pagination {
  width: 100%;
  font-size: 0.875rem;

  > div:first-child {
    padding-left: 0;
    line-height: 2.5rem;
  }

  .page-list {
    padding: 0.375rem;
    margin-bottom: 0;
    background: $white;

    display: flex;
    gap: 10px;

    li {
      display: inline;
    }
  }

  a {
    font-weight: 600;
    color: $black;
    display: inline-flex;
    min-width: 24px;
    height: 1.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    &:not(.previous):not(.next) {
      letter-spacing: 0.125rem;
    }

    @media screen and (max-width: 750px) {
      font-size: 1rem;
    }
  }

  .disabled {
    color: $gray;
  }

  .current a {
    font-size: 1.25rem;
    color: $brand-primary;
    text-decoration: none;
  }
}

.active_filters {
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;
  background: #dededd;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }

  ul {
    display: inline;
  }

  .filter-block {
    display: inline-block;
    padding: 0.625rem;
    margin-right: $small-space;
    margin-bottom: $small-space;
    font-size: $font-size-xs;
    color: $gray-darker;
    background: $white;

    .close {
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
      font-size: $font-size-lg;
      color: $gray-darker;
      opacity: 1;
    }
  }
}

.block-categories {
  padding: 1.563rem 1.25rem;
  margin-bottom: 1.563rem;
  background: $white;

  .h6 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .category-sub-menu {
    margin-top: 1rem;

    .category-sub-link {
      font-size: $font-size-sm;
    }

    li {
      position: relative;

      &[data-depth="1"] {
        margin-bottom: $small-space;
      }
    }

    li[data-depth="0"] > a {
      display: inline-block;
      width: 100%;
      padding-bottom: 0.1875rem;
      margin: 0.3125rem 0 0;
      font-weight: 600;
    }

    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: 0.3125rem;

      &::before {
        margin-right: 0.3125rem;
        content: "-";
      }
    }
  }

  a {
    color: $gray-darker;
  }

  .collapse-icons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    cursor: pointer;

    &[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .add,
    .remove {
      &:hover {
        color: $brand-primary;
      }
    }

    .remove {
      display: none;
    }
  }

  .arrows {
    .arrow-right,
    .arrow-down {
      margin-left: 2px;
      font-size: $font-size-sm;
      cursor: pointer;

      &:hover {
        color: $brand-primary;
      }
    }

    .arrow-down {
      display: none;
    }

    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }

      .arrow-down {
        display: inline-block;
      }
    }
  }
}

.facets-title {
  color: $gray-darker;
}

.advertising-block {
  margin-bottom: 1.563rem;

  img {
    width: 100%;
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #category {
    #left-column {

      #search_filters_wrapper {
        margin-right: -30px;
        margin-left: -30px;
      }

      #search_filter_controls {
        margin-bottom: 1rem;
        text-align: center;

        button {
          margin: 0 0.5rem;
        }
      }

      #search_filters {
        padding: 0;
        margin-bottom: 20px;
        border-top: 1px solid $gray-lighter;
        box-shadow: none;

        .ui-slider-horizontal {
          margin-bottom: 20px;
        }

        .facet {
          padding-top: 0;
          border-bottom: 1px solid $gray-lighter;

          .title {
            align-items: center;
            cursor: pointer;

            .collapse-icons .remove {
              display: none;
            }
          }

          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }

              .remove {
                display: block;
              }
            }
          }

          .facet-title {
            color: $gray-darker;
            text-transform: uppercase;
          }

          .h6 {
            display: inline-block;
            padding: 1.25rem;
            margin-bottom: 0;
          }

          .navbar-toggler {
            display: inline-block;
            height: inherit;
            padding: 0 0.625rem 0 0;
          }

          .collapse {
            display: none;

            &.in {
              display: block;
            }
          }

          .facet-label {
            a {
              margin-top: 0;
            }
          }

          ul {
            margin-bottom: 0;

            li {
              padding: 1.25rem;
              border-top: 1px solid $gray-lighter;
            }
          }
        }
      }
    }

    #search_filter_toggler {
      width: 100%;
    }
  }

  .products-sort-order {
    .select-title {
      margin-left: 0;
    }
  }

  .products-selection {
    h1 {
      padding-top: 0;
      margin-bottom: 1rem;
      text-align: center;
    }

    .showing {
      padding-top: 1rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .products-selection {
    .filter-button {
      padding-left: 0;
    }
  }

  #category {
    #left-column {
      #search_filters_wrapper {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .category-cover {
    display: none;
  }

  .block-category {
    min-height: 0;
    margin-bottom: 0;
  }

  .card-block {
    padding: 0;
  }

  .card {
    background-color: inherit;
    border: none;
    box-shadow: none;
  }
}

@include media-breakpoint-down(md) {
  #products {
    .products {
      justify-content: flex-start;
    }
  }
}


.subcategories-main {
  max-width: 80dvw;
  margin: 0 auto;
  border-top: 0;

  .card-block {
    padding: 0;
  }
}
