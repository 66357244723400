@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";

@import "lib/bootstrap";

@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";

@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/customer";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/subcategories";
@import "components/products";
@import "components/mini-cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/newsletter";
@import "components/brands";
@import "components/header";
@import "components/wrapper";
@import "components/main";
@import "components/notifications";
@import "components/progressbar";
@import "components/inputs";

.row {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
/* stylelint-enable */

#search_widget {
    float: none !important;
    width: 20rem !important;
}

.red {
    color: #c8103f;
}

.search-title-custom {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    letter-spacing: -0.2px;
    margin-bottom: 0;
    text-transform: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.banner-wrapper {
    background: #abc2ab;
    @include media-breakpoint-down(sm) {
        height: 250px;
    }
    height: 150px;
    margin-bottom: 4rem;
}
.banner-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 32px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    @include media-breakpoint-down(sm) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        span {
            text-align: center;
            font-size: 12px;
        }
    }
    span {
        color: white;
    }
}
.vl {
    margin: 0 20px 50px 20px;
}
@include media-breakpoint-down(sm) {
    .vl {
        border-left: 1px solid white;
        height: 58px;
        margin: 0px 10px 50px 10px !important;
    }
}
.banner-hp {
    max-width: 1600px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}

ol {
    color: #7a7a7a;
}

#content-hook_order_confirmation,
#content-hook_payment_return,
.forgot-container,
.smart-blog-bottom-pagination {
    max-width: 80dvw !important;
    margin: 0 auto !important;
}

#content-hook_order_confirmation {
    margin-bottom: 120px !important;
}

#smartblogcat {
    display: grid;
    max-width: 80dvw;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    grid-auto-rows: 1fr;
    gap: 20px;

    @media only screen and (max-width: 550px) {
        grid-template-columns: 1fr;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }
}

.sdsarticleCat {
    .smart-blog-post-single-item {
        height: 340px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;

        @media only screen and (min-width: 768px) {
            height: 400px;
        }

        .articleContent {
            height: 100%;

            a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                height: 100%;
            }

            .sdsarticleHeader {
                padding-bottom: 0;
            }

            .smart-blog-posts-title {
                font-size: 16px;
                line-height: 18px;
                margin: 0;

                @media only screen and (min-width: 768px) {
                    font-size: 25px;
                    line-height: 35px;
                }
            }

            .smart-blog-posts-short-description {
                margin-top: auto;
                margin-bottom: 0;
                max-height: 70px;
                height: 70px;
                min-height: 70px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .imageFeatured {
                border-style: none;
                vertical-align: middle;
                max-height: 200px;
                height: 200px;
                min-height: 200px;
                -o-object-fit: cover;
                   object-fit: cover;
                width: 100%;
            }
        }
    }
}

.auth_page,
.page-footer {
    max-width: 80dvw;
    width: 100%;
    margin: 0 auto;
}

.smart-blog-breadcrumb {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    padding: 0 !important;
}

.smart-blog-single-post-title {
    font-family: "PT Sans", sans-serif !important;
}
.alert-info {
    color: #4e734e;
    background-color: #abc2ab80;
    border-color: #abc2ab80;
}
.inputwrapper::after {
    content: attr(data-required);
    position: absolute;
    right: 8px;
    top: 50%;
    font-size: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #ccc;
}
h5 {
    font-family: Manrope, sans-serif;
    font-size: 26px !important;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
}
/* inputs*/
.card-title {
    color: #4e734e;
}
.remove-from-cart {
    color: grey;
}

#cart_remaining_free_shipping,
#remaining_amount_free_shipping {
    background-color: #abc2ab80;
    #cart_remaining_free_shipping_start_price,
    #cart_remaining_free_shipping_end_price {
        display: none;
    }
    .col-xs-12 {
        .row .col-5 {
            float: unset;
            margin: 0 auto;
        }
    }
    .bg-success,
    .bg-warning,
    .progress-bar {
        background-color: #4e734e !important;
    }
}
h5 {
    font-size: 26px !important;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
}
#cart_remaining_free_shipping-text,
#remaining_amount_free_shipping-text {
    color: #4e734e;
}
#cart_remaining_free_shipping_bar,
#remaining_amount_free_shipping {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
}
hr {
    border-top: unset;
}
.fa-pts-close {
    display: none;
}
.btn_place_order.fa-pts-sopping-cart {
    display: none;
}
.blockreassurance_product {
    display: none !important;
}
/*login form*/
.input-group-addon {
    width: 7%;
}

#opc_main {
    .btn.btn-primary {
        border-radius: 0.3rem;
    }
    .btn.btn-secondary {
        border: 1px solid black;
        border-radius: 0.3rem;
    }
}

.btn {
    -webkit-transition: 200ms all ease-in-out;
    transition: 200ms all ease-in-out;
}

/* cookieBot */

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyLink {
    @media screen and (min-width: 1280px) {
        -webkit-box-flex: 0 !important;
            -ms-flex: 0 !important;
                flex: 0 !important;
        width: 95% !important;
        margin-top: 0.3rem;
    }
}
