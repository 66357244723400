#wrapper {
  background: $white;
  margin-top: 10px;

  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }

  .breadcrumb {
    margin: 20px auto;
    padding: 0;
    background: transparent;
    max-width: 80dvw;
    width: 100%;
    

    @media screen and (max-width: 480px) {
      padding-inline: 15px;;
    }

    &[data-depth="0"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;

      &::after {
        margin: 0.3125rem;
        color: $gray;
        content: ">";
      }

      &:last-child {
        margin: 0;
        color: $gray;
        content: ">";

        &::after {
          content: "";
        }
      }

      a {
        color: hsla(0,0%,40%,.7);

        &:hover {
          color: #111;
        }
      }
    }
  }
}
