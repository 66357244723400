$thumbnail-size: 250px;
$product-description-height: 70px;

#products,
.featured-products,
.product-accessories {
  max-width: 80dvw;
  margin: 0 auto;


  .category-title {
    margin: 0 15px;
    font-weight: 500;
    color: #5A5A5A;
    text-align: center;
    font-family: 'Botanika Mono', sans-serif;
    font-size: 22px;

    @media screen and (max-width: 480px) {
      line-height: 36px;
    }
  }

  .category-list {
    display: flex;
    margin-top: 30px;
    margin-bottom: 50px;

    @media screen and (max-width: 950px) {
      .category-box-title {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  .category-item {
    margin: 0 10px; width: 33%; max-width: 33%; box-shadow: 0 3px 6px -4px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);

    @media screen and (max-width: 480px) {
      width: auto;
      max-width: 100%;
      margin: 10px 10px;
    }

    .img-holder {
      overflow: hidden;
    }

    &:hover {


      .category-img {
        transform: scale(1.1);
      }
    }

    .category-img {
      width: 100%;
      transition: filter .6s,opacity .6s,transform .6s,box-shadow .3s;
      object-fit: cover;
    }

    .category-box {
      text-align: center;
      padding-inline: 10px;
      padding-bottom: 1.5rem;
      padding-top: 18px;

    .category-box-title {
      font-weight: 500;
      color: #000;
      font-size: 25px;
      text-transform: uppercase;
      font-family: 'Botanika Mono', sans-serif;
      text-align: center;
    }

    .title-grey {
      color: #5A5A5A;
    }
  }
  }

  b {
    background-color: currentColor;
    display: block;
    flex: 1;
    height: 2px;
    opacity: .1;
  }

  .products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0.8rem;

    // Show first 10 products and hide the rest
    &.row-cap > .product {
      &:nth-child(-n+10) {
        display: block;
      }
      &:nth-child(n+11) {
        display: none;
      }
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      // Show first 8 products and hide the rest
      &.row-cap > .product {
        &:nth-child(-n+8) {
          display: block;
        }
        &:nth-child(n+9) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;

      // Show first 9 products and hide the rest
      &.row-cap > .product {
        &:nth-child(-n+9) {
          display: block;
        }
        &:nth-child(n+10) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr 1fr;

      // Show first 8 products and hide the rest
      &.row-cap > .product {
        &:nth-child(-n+8) {
          display: block;
        }
        &:nth-child(n+9) {
          display: none;
        }
      }
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr !important;

      // Show first 5 products and hide the rest
      &.row-cap > .product {
        &:nth-child(-n+5) {
          display: block !important;
        }
        &:nth-child(n+6) {
          display: none !important;;
        }
      }
    }
  }

  .products-section-title {
    margin: 2.5rem 0;
    font-weight: 500;
    text-align: center;
    color: #5A5A5A;
  }

  .all-product-link-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .all-product-link {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    clear: both;
    font-weight: 500;
    color: $gray;

    padding: 0.5rem 1.25rem;
    font-weight: 600;
    text-transform: uppercase;

    background: #bebebe;

    color: #fff;

    @at-root .lang-rtl & {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
}

.product-miniature {
  width: 100%;
  cursor: pointer;
  transition: all 1s;




  .product {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 0.8125rem;

    @at-root .page-index &, .page-search & {
      width: 25%;
      min-width: 250px;
    }
  }

  .product-thumbnail {
    display: block;

    position: relative;

    .image-hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .6s ease-out;
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      &:has(.image-hover) {
        .image-hover:hover {
          opacity: 1;
          width: 100%;
        }
      }
    }
  }

  .product-category {
    text-align: center;
    text-decoration: none;
    margin-top: 0.1em;
    font-size: .75em;
    line-height: 1.8;
    text-transform: uppercase;
    color: #888888;
    font-weight: 700;
  }

  .product-title {
    margin-bottom: 0!important;
    margin-top: .3rem!important;
  }

  .product-title a {
    font-size: 15.8px;
    font-weight: 700;
    color: #5a5a5a;
    text-align: center;
    text-decoration: none;

    line-height: 20px;
    text-transform: initial;
  }

  .thumbnail-container {
    position: relative;
    height: auto;
    margin-bottom: 1.563rem;
    overflow: hidden;
    background: $white;

    transition: all 125ms ease-in-out;

    &:hover,
    &:focus {
      .highlighted-informations {
        top: calc(100% - 4.4rem);

        &::after {
          opacity: 1;
        }

        &.no-variants {
          top: calc(100% - 2.5rem);
        }
      }

      /*.product-description::after {
        position: absolute;
        top: 0;
        left: 10%;
        width: 80%;
        content: "";
        border-top: $gray 1px solid;
        opacity: 0.25;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }*/


        /* box-shadow: 0 3px 6px -4px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23); */
        outline: 1px solid #999;

    }

    .product-thumbnail {
      position: relative;
      height: calc(100% - #{$product-description-height});

      img {
        position: relative;
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
        margin: auto;
      }
    }
  }

  .product-title {
    margin-top: 0.7rem;
    text-align: center;
    text-transform: capitalize;
  }

  .product-price-and-shipping {
    font-weight: 700;
    color: $gray-darker;
    text-align: center;
    margin-top: 0.5rem;

    .discount-product {
      display: none;
    }
  }

  .variant-links {
    position: relative;
    top: -0.25em;
    width: 100%;
    min-height: 2.5rem;
    padding-top: 0.1875rem;
    text-align: center;
    background: $white;
  }

  .thumbnail-top {
    position: relative;
    overflow: hidden;
  }

  .highlighted-informations {
    position: absolute;
    top: 100%;
    z-index: 2;
    width: 100%;
    height: auto;
    padding: 0.625rem 0;
    text-align: center;
    background: $white;
    transition: 0.3s;

    @include media-breakpoint-down(xs) {
      display: none;
    }

    .quick-view {
      font-size: $base-font-size;
      color: $gray;

      &:hover {
        color: $brand-primary;
      }
    }
  }

  .product-description {
    position: relative;
    bottom: 0;
    height: auto;
    padding: 0.25rem;
    padding-bottom: 0.7rem;
    background: $white;
  }

  .product-flags {
    li.product-flag {
      min-width: 3.125rem;
      min-height: 1.875rem;
      font-weight: 600;

      &.online-only {
        top: 13.1rem;
      }
    }
  }

  .comments_note {
    color: $gray;
    text-align: center;
  }

  .regular-price {
    display: inline-block;
    font-size: $font-size-sm;
    color: $gray;
    text-decoration: line-through;
  }

  .count {
    position: relative;
    bottom: 0.5rem;
    font-weight: 700;
    color: $gray;
  }
}

@include media-breakpoint-down(md) {
  .featured-products {
    .products {
      justify-content: center;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.wishlist-button-add {
  display: none!important;
}
