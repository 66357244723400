.block_newsletter_webit {
  max-width: 80dvw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;

  .newsletter-text {
    display: flex; 
    flex-direction: column; 
    width: 66.6%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  #form-email {
    display: flex;
    width: 33%;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
  }

  @include search-box();
  float: inherit;
  @include media-breakpoint-down(sm) {
    max-width: 95%;
  }

  margin: auto;
  margin-bottom: 0.625rem;
  clear: both;
  font-size: $font-size-sm;
  background: $white;

  #block-newsletter-label {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6;
    color: $black;
    padding-left: 0;
    margin-bottom: 0.625rem;
  }
  .heading {
    font-size: 13.44px!important;
  }
  .footer_newsletter {
    padding-left: 0px!important;
  }
  form {
    .input-wrapper {
      overflow: hidden;
    }

    .row {
      .col-xs-12:last-of-type {
        p {
          font-size: 0.75rem;
          color: $gray;
        }
      }

      .col-xs-12:first-of-type {
        .btn-primary:first-of-type {
          margin-left: 0.5rem;
        }
      }
    }

    input[type="email"] {
      padding: 11px;
      border: 1px solid rgba(0,0,0,.25);
      width:100%;

      &:focus {
        padding: 8px 8px 9px;
        box-shadow: inset 0 -1.8em 1em 0 transparent;
        outline: 0;
      }
    }

    input[type="text"] {
      width: 100% !important;
    }

    .input-wrapper {
      display: flex;
    }

    input {
      height: 42px;
      box-shadow: none;
    }
  }
}

#footer {
  display: flex;
  flex-direction: column;
  
  .block_newsletter {
    padding-left: 0;
    p {
      padding-top: $small-space;
    }

    #block-newsletter-label {
      padding-top: 0;
    }
  }
}
.block-newsletter-inside {
  padding-left: 0;
}
.newsletter {
  background: #BEBEBE;
  width: 35%;
  color: white;
}

#left-column {
  .block_newsletter {
    padding: 1.563rem 1.25rem;
    margin-bottom: 1.563rem;
  }
}

.modal {
  display: none;
  .modal-overlay{
    z-index: 1000;
    background-color: black;
    opacity: 0.8;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .close {
    background-image: url("../img/close.png");
    z-index: 100020;
    position: absolute;
    float: left;
    top: -18px;
    right: 20px;
    width: 30px;
    cursor: pointer;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
  }

}
.modal-theme {
  z-index: 100010;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#modal-dialog {
  box-sizing: content-box; max-height: 935px; max-width: 626px; border-style: solid; border-color: rgb(255, 0, 0); border-radius: 7px; border-width: 0px; padding: 12px; width: 90%; background-repeat: no-repeat; background-position: center center; background-color: rgb(255, 255, 255); box-shadow: rgb(204, 204, 204) 0px 0px 0px 0px; overflow: auto;
}
.modal-form {
  #block-newsletter-inside{
    width: 90%!important;
  }
  #form-email{
    input{
      height: 20px!important;
    }
    input[type="email"] {
      padding: 8px;
      width: calc(100% - 18px);
    }
  }

  .block_newsletter {
    flex-direction: column;
    align-items: center;

    .newsletter-text {
      >p:first-child {
        display: none;
      }
    }

    .btn {
      width: calc(100% - 2.5rem) !important;
    }
  }
  
}

.modal-form .wrapper form {

  input[type="email"] {
    width: 60%!important;
    height: auto!important;
  }
  input[type="text"] {
    width: 60%!important;
    height: auto!important;
  }
  .btn-secondary {
    width: 60%!important;
    background: #456d44;
  }
}
@include media-breakpoint-down(sm) {
  .modal-theme {
    left: 51%;
    width: 95%;
  }
  .modal-form {
    #blockEmailSubscription_displayFooterBefore{
      padding-left: 0!important;
    }
    #form-email{
      width: 90%!important;
      float: left;
    }
  }
  .block_newsletter {
    padding-top: 1rem;
  }
}


.email-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .input-wrapper {
    width: 100%;

    input {
      min-width: 0px !important;
      outline: none;
    }
  }

  .input-row {
    display: flex;
    gap: 0.5rem;
    width: 100%;
  }

  .btn {
    width: 100%;
    text-transform: none;
  }
}

.modal-newsletter-label {
  width: 100%;
  text-align: center;
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 1.6;
  color: $black;
  padding-left: 0;
  margin: 0.625rem 0;
}

.modal-newsletter-inside {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 0.7rem;
}

.modal-form {
  width: 100%;
  input.newsletter {
    width: auto !important;
  }

  .block_newsletter_webit {
    justify-content: center !important;
  }
}