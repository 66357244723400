#products,
#pagenotfound {
    #main {
        .page-header {
            margin: 2rem 0 3rem;
        }

        .page-content {
            margin-bottom: 10rem;
        }
    }

    .page-not-found {
        max-width: 1597px;
        padding: 1rem;
        margin: 0 auto;
        overflow: hidden;
        font-size: $font-size-sm;
        color: $gray;
        background: $white;

        .container_404 {
            max-width: 80dvw;
            margin: 0 auto;
            display: flex;
            gap: 2rem;
            flex-direction: row;
            margin-top: 60px;
            align-items: center;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        .container_404_left {
            width: 30%;
            font-size: 6em;
            font-weight: bold;
            opacity: 0.3;
            color: #000;
            text-align: center;

            @media screen and (max-width: 768px) {
                width: 100%;
                height: 60px;
            }
        }

        .container_404_right {
            h4 {
                color: #000;
                font-weight: 700;
                font-size: 1.7em;
                line-height: 1.3em;
                margin: 0.5rem 0 1rem;
            }

            p {
                color: #000;
                font-size: 18px;
                line-height: 28px;
            }

            @media screen and (max-width: 768px) {
                h4 {
                    font-size: 1.5em;
                    text-align: center;
                }

                p {
                    font-size: 16px;
                    text-align: center;
                }
            }

            #search_widget {
                margin-top: 1.5rem;

                button {
                    width: 55px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    background-color: white;
                    border-radius: 0;
                    border: 2px solid #e1e1e1;

                    svg {
                        position: inherit;
                        height: 24px;
                        width: 24px;

                        path {
                            fill: #7b7b7b;
                        }
                    }
                }

                @media screen and (max-width: 768px) {
                    width: 100% !important;
                }
            }
        }
    }
}
