.collapse-content {
	@media only screen and (max-width: 768px) {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-out;
	}
}

.mobile-navbar-logo-icons {
	display: none;
	padding-top: 0.5rem;
	width: 100%;

	.logo-link {
		width: 130px;
	}

	.icons-links {
		align-items: center;
		display: flex;
		gap: 0.7rem;
		justify-content: center;
		margin-top: 0.3rem;
	}

	.logo-link svg {
		fill: #fff;
		height: 2rem;
		object-fit: cover;
		width: 100%;
	}

	@media only screen and (max-width: 768px) {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}