
.shopping-cart-wrapper {
	background: transparent;
	display: block;
	opacity: 0;
	padding: 5px 0;
	position: absolute;
	right: 9.3dvw;
	top: 100%;
	transform: translateX(150px) translateY(-150px) scale(0.0);
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
	z-index: 999;
	content-visibility: hidden;
}

.shopping-cart-popup {
	background: white;
	border: 2px solid #ddd;
	border-radius: 3px;
	box-shadow: 1px 1px 15px rgba(0, 0, 0, .15);
	float: right;
	padding: 20px;
	position: relative;
	width: 380px;

	&.active {
		display: block;
	}

	.shopping-cart-header {
		border-bottom: 1px solid #e8e8e8;
		padding-bottom: 15px;

		.shopping-cart-total {
			float: right;
		}
	}

	.shopping-cart-items {

		padding-top: 20px;

		li {
			margin-bottom: 18px;
		}

		img,
		picture {
			float: left;
			margin-right: 1rem;
		}

		.item-name {
			color: #456d44;
			display: block;
			font-size: 16px;
			font-weight: bold;
			padding-bottom: 10px;
			padding-top: 10px;

			a {
				color: #456d44 !important;
				display: block;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.item-price {
			color: #000;
			font-weight: 700;
			margin-right: 8px;
		}

		.item-quantity {
			color: #000;
		}
	}

	.cart-wishlist-action {
		display: flex;
		gap: 1rem;
		justify-content: space-between;

		.cart-wishlist-viewcart {
			align-items: center;
			background-color: #f6f6f6;
			border: 2px #d9d9d9 solid;
			border-radius: 4px;
			color: #232323;
			display: flex;
			font-weight: 600;
			padding: 0.5rem 1.25rem;
			text-transform: uppercase;

			&:hover {
				color: #232323;
			}
		}

		.cart-wishlist-checkout {
			align-items: center;
			background-color: #a3012e;
			border: 2px #a3012e solid;
			border-radius: 4px;
			color: #fff !important;
			display: flex;
			font-weight: 600;
			padding: 0.5rem 1.25rem;
			text-transform: uppercase;

			&:hover {
				color: #fff !important;
			}
		}
	}

	&:after {
		background-color: #fff;
		border-left: 2px solid rgba(0, 0, 0, .15);
		border-top: 2px solid rgba(0, 0, 0, .15);
		content: ' ';
		height: 10px;
		left: 93%;
		pointer-events: none;
		position: absolute;
		top: -6px;
		transform: rotate(45deg);
		transform-origin: center center;
		width: 10px;
	}
}

#_desktop_cart:hover + .shopping-cart-wrapper,
.shopping-cart-wrapper:hover {
	opacity: 1;
	transform: translateY(0);
	content-visibility: visible;
}