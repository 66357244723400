.navbar a {
	float: left;
	font-size: 16px;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

.dropdown-link {
	// color: #fff !important;
	font-size: 1rem;
	font-weight: normal;
	line-height: 34px;
	transition: all .2s;
}

.dropdown-link:hover {
	// background-color: #fff !important;
	// color: hsla(0, 0%, 7%, .85) !important;
}

.dropdown-link-level-1 {
	// background-color: #262626 !important;
	// color: #fff !important;
	display: flex !important;
	font-size: 1rem;
	font-weight: normal;
	justify-content: space-between;
	line-height: 34px;
	transition: all .2s;
}

.dropdown-link-level-1:hover {
	// background-color: #fff !important;
	// color: #262626 !important;
}