.footer-blog-wrapper {
	.blog-list-grid {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		margin: 0 auto;
		max-width: 80dvw;

		.article-item {
			box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
			container-type: inline-size;
			margin: 0;
			max-width: 100%;
			overflow: hidden;
			width: auto;

			.article-img {
				object-fit: cover;
				transition: filter .6s, opacity .6s, transform .6s, box-shadow .3s;
				width: 100%;
			}

			&:hover {
				.article-img {
					transform: scale(1.1);
				}
			}

			.box-image {
				position: relative;

				.article-title {
					font-size: 1.2rem;
					font-weight: 700;
				}

				.article-info {
					bottom: 0;
					color: #fff;
					padding: 10px;
					position: absolute;
					text-align: center;
					text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
					z-index: 1;
				}
			}

			.img-holder {
				aspect-ratio: 1;
				overflow: hidden;

				@container (width > 350px) {
					aspect-ratio: auto;
					height: 350px;
				}

				@media screen and (max-width: 480px) {
					aspect-ratio: auto !important;
					height: 230px;
				}

				img {
					height: 100%;
					object-position: center center;
				}
			}
		}

		.article-box {
			padding-bottom: 1.5rem;
			padding-inline: 10px;
			padding-top: 18px;
			text-align: center;

			.article-box-title {
				color: #000;
				font-family: 'Botanika Mono', sans-serif;
				font-size: 25px;
				font-weight: 500;
				text-align: center;
				text-transform: uppercase;
			}

			.title-grey {
				color: #5a5a5a;
			}
		}
	}
}