.dropdown, .dropleft {
	position: relative;

	span {
		display: none;
	}

	&:hover {
		> .dropdown-content,
		> .dropleft-content {
			display: block;
		}
	}
}

.dropdown .dropbtn {
	background-color: inherit;
	border: none;
	color: white;
	font-size: 16px;
	margin: 0;
	outline: none;
	padding: 14px 16px;
}

.dropleft {
	@media only screen and (max-width: 768px) {
		span {
			display: inline-block !important;
		}
	}
}

.dropdown-content {
	background-color: var(--background);
	border: 2px solid #ddd;
	border-radius: 5px;
	border-top: 0;
	box-shadow: 1px 1px 15px rgba(0, 0, 0, .15);

	color: hsla(0, 0%, 7%, .85);
	display: none;

	min-width: 260px;
	position: absolute;
	top: 62px;
	z-index: 1;

	&:before {
		border: 11px solid transparent;
		border-bottom-color: #ddd;
		bottom: -2px;
		content: '';
		height: 0;
		left: 50%;
		margin-left: -11px;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		width: 0;
		z-index: -999;
	}

	&:after {
		border: 8px solid hsla(0, 0%, 87%, 0);
		border-bottom-color: #fff;
		bottom: -2px;
		content: '';
		height: 0;
		left: 50%;
		margin-left: -8px;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		width: 0;
		z-index: 10;
	}

	a {
		border-bottom: 1px solid #ececec;
		color: var(--color);
		display: block;
		float: none;
		padding: 10px 20px;
		text-align: left;
		text-decoration: none;

		&:last-child {
			border-bottom: none;
		}
	}
}

.dropleft-content {
	right: 100%;
	top: 0 !important;

	@media only screen and (max-width: 768px) {
		border: 0;
		border-bottom: 1px solid #fff;
		border-top: 1px solid #fff;
		display: block;
		left: 0;
		position: relative !important;
	}
}
