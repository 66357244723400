$thumbnail-size: 250px;
$product-description-height: 70px;

#products,
.featured-products,
.product-accessories {
	margin: 0 auto;
	max-width: 80dvw;

	.category-title {
		color: #232323;
		font-family: 'Botanika Mono', sans-serif;
		font-size: 22px;
		font-weight: 500;
		margin: 0 15px;
		text-align: center;

		@media screen and (max-width: 480px) {
			line-height: 36px;
		}
	}

	.category-list {
		display: flex;
		margin-bottom: 50px;
		margin-top: 30px;

		@media screen and (max-width: 950px) {
			.category-box-title {
				font-size: 18px;
			}
		}

		@media screen and (max-width: 480px) {
			flex-direction: column;
		}
	}

	b {
		background-color: currentColor;
		display: block;
		flex: 1;
		height: 2px;
		opacity: .1;
	}

	.products {
		display: grid;
		gap: 0.8rem;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

		// Show first 10 products and hide the rest
		&.row-cap > .product {
			&:nth-child(-n+10) {
				display: block;
			}

			&:nth-child(n+11) {
				display: none;
			}
		}

		@media screen and (max-width: 1200px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;

			// Show first 8 products and hide the rest
			&.row-cap > .product {
				&:nth-child(-n+8) {
					display: block;
				}

				&:nth-child(n+9) {
					display: none;
				}
			}
		}

		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr 1fr 1fr;

			// Show first 9 products and hide the rest
			&.row-cap > .product {
				&:nth-child(-n+9) {
					display: block;
				}

				&:nth-child(n+10) {
					display: none;
				}
			}
		}

		@media screen and (max-width: 550px) {
			grid-template-columns: 1fr 1fr;

			// Show first 8 products and hide the rest
			&.row-cap > .product {
				&:nth-child(-n+8) {
					display: block;
				}

				&:nth-child(n+9) {
					display: none;
				}
			}
		}

		@media screen and (max-width: 480px) {
			grid-template-columns: 1fr !important;

			// Show first 5 products and hide the rest
			&.row-cap > .product {
				&:nth-child(-n+5) {
					display: block !important;
				}

				&:nth-child(n+6) {
					display: none !important;;
				}
			}
		}
	}

	.products-section-title {
		color: #232323;
		font-weight: 500;
		margin: 2.5rem 0;
		text-align: center;
	}

	.all-product-link-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.all-product-link {
		align-items: center;
		background: #bebebe;
		clear: both;

		color: $gray;
		color: #fff;
		display: flex;
		font-weight: 500;
		font-weight: 600;

		justify-content: center;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;

		padding: 0.5rem 1.25rem;

		text-transform: uppercase;

		@at-root .lang-rtl & {
			.material-icons {
				transform: rotate(-180deg);
			}
		}
	}
}

.category-list-grid {
	--cols: 4;
	--rows: 2;
	display: grid;
	grid-auto-rows: 0;
	grid-gap: 10px;
	grid-template-columns: repeat(var(--cols), 1fr);
	grid-template-rows: repeat(var(--rows), auto);

	margin-bottom: 30px;
	margin-top: 30px;
	--cols-medium: 3;
	--rows-medium: 2;
	padding-bottom: 10px;
	--cols-small: 2;
	--rows-small: 3;
	--rows-xs: 4;

	@media screen and (max-width: 1000px) {
		grid-template-columns: repeat(var(--cols-medium), 1fr);
		grid-template-rows: repeat(var(--rows-medium), auto);
	}

	@media screen and (max-width: 750px) {
		grid-template-columns: repeat(var(--cols-small), 1fr);
		grid-template-rows: repeat(var(--rows-small), auto);
	}

	@media screen and (max-width: 500px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(var(--rows-xs), auto);
	}

	.category-item {
		margin: 0;
		max-width: 100%;
		overflow-y: hidden;
		width: auto;

		.img-holder {
			aspect-ratio: 1;

			img {
				height: 100%;
				object-position: center center;
			}
		}
	}
}

.category-item {
	max-width: 33%;
	width: 33%;
}

.category-item {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
	margin: 0 10px;

	@media screen and (max-width: 480px) {
		margin: 10px 10px;
		max-width: 100%;
		width: auto;
	}

	.img-holder {
		overflow: hidden;

		@media screen and (max-width: 480px) {
			aspect-ratio: auto !important;
			height: 230px;
		}
	}

	&:hover {
		.category-img {
			transform: scale(1.1);
		}
	}

	.category-img {
		object-fit: cover;
		transition: filter .6s, opacity .6s, transform .6s, box-shadow .3s;
		width: 100%;
	}

	.category-box {
		padding-bottom: 1.5rem;
		padding-inline: 10px;
		padding-top: 18px;
		text-align: center;

		.category-box-title {
			color: #000;
			font-family: 'Botanika Mono', sans-serif;
			font-size: 25px;
			font-weight: 500;
			text-align: center;
			text-transform: uppercase;
		}

		.title-grey {
			color: #5a5a5a;
		}
	}
}

.product-miniature {
	content-visibility: auto;
	cursor: pointer;
	transition: all 1s;
	width: 100%;

	.product {
		display: flex;
		justify-content: center;
		margin: 0 0.8125rem;
		padding: 0;

		@at-root .page-index &, .page-search & {
			width: 25%;
			min-width: 250px;
		}
	}

	.product-thumbnail {
		display: block;
		position: relative;

		.image-hover {
			content-visibility: hidden;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			transition: opacity .6s ease-out;
			width: 100%;
		}

		@media screen and (min-width: 768px) {
			&:has(.image-hover) {
				.image-hover:hover {
					content-visibility: visible;
					opacity: 1;
					width: 100%;
				}
			}
		}

		img {
			@media screen and (max-width: 480px) {
				height: $thumbnail-size !important;
				object-fit: contain;
			}
		}
	}

	.product-category {
		color: #757575;
		font-size: .75em;
		font-weight: 700;
		line-height: 1.8;
		margin-top: 0.1em;
		text-align: center;
		// color: #888888;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product-title {
		margin-bottom: 0 !important;
		margin-top: .3rem !important;
	}

	.product-title a {
		color: #333;
		font-size: 15.8px;
		// color: #5a5a5a;
		font-weight: 700;
		line-height: 20px;
		text-align: center;

		text-decoration: none;
		text-transform: initial;
	}

	.thumbnail-container {
		background: $white;
		height: auto;
		margin-bottom: 1.563rem;
		overflow: hidden;
		position: relative;

		transition: all 125ms ease-in-out;

		&:hover,
		&:focus {
			outline: 1px solid #999;

			/*.product-description::after {
				position: absolute;
				top: 0;
				left: 10%;
				width: 80%;
				content: "";
				border-top: $gray 1px solid;
				opacity: 0.25;

				@include media-breakpoint-down(xs) {
					display: none;
				}
			}*/


			/* box-shadow: 0 3px 6px -4px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23); */
			.highlighted-informations {
				top: calc(100% - 4.4rem);

				&::after {
					opacity: 1;
				}

				&.no-variants {
					top: calc(100% - 2.5rem);
				}
			}

		}

		.product-thumbnail {
			height: calc(100% - #{$product-description-height});
			position: relative;

			img {
				display: block;
				height: auto;
				margin: auto;
				max-width: 100%;
				position: relative;
				width: 100%;

				&[loading='lazy'] {
					width: 98%;
				}
			}
		}
	}

	.product-title {
		margin-top: 0.7rem;
		text-align: center;
		text-transform: capitalize;
	}

	.product-price-and-shipping {
		color: $gray-darker;
		font-weight: 700;
		margin-top: 0.5rem;
		text-align: center;

		.discount-product {
			display: none;
		}
	}

	.variant-links {
		background: $white;
		min-height: 2.5rem;
		padding-top: 0.1875rem;
		position: relative;
		text-align: center;
		top: -0.25em;
		width: 100%;
	}

	.thumbnail-top {
		overflow: hidden;
		position: relative;
	}

	.highlighted-informations {
		background: $white;
		height: auto;
		padding: 0.625rem 0;
		position: absolute;
		text-align: center;
		top: 100%;
		transition: 0.3s;
		width: 100%;
		z-index: 2;

		@include media-breakpoint-down(xs) {
			display: none;
		}

		.quick-view {
			color: $gray;
			font-size: $base-font-size;

			&:hover {
				color: $brand-primary;
			}
		}
	}

	.product-description {
		background: $white;
		bottom: 0;
		height: auto;
		padding: 0.25rem;
		padding-bottom: 0.7rem;
		position: relative;
	}

	.product-flags {
		li.product-flag {
			font-weight: 600;
			min-height: 1.875rem;
			min-width: 3.125rem;

			&.online-only {
				top: 13.1rem;
			}
		}
	}

	.comments_note {
		color: $gray;
		text-align: center;
	}

	.regular-price {
		color: $gray;
		display: inline-block;
		font-size: $font-size-sm;
		text-decoration: line-through;
	}

	.count {
		bottom: 0.5rem;
		color: $gray;
		font-weight: 700;
		position: relative;
	}
}

@include media-breakpoint-down(md) {
	.featured-products {
		.products {
			grid-template-columns: 1fr 1fr;
			justify-content: center;
		}
	}
}

.wishlist-button-add {
	display: none !important;
}
