// search popup
.ui-menu {
	li a.ui-state-active {
		color: #000;
	}

	.all-results {
		a:hover {
			background-color: transparent !important;
		}
	}
}