.back-to-top {
	align-items: center;
	border: 1px solid #000;
	border-radius: 78px;
	bottom: 20px;
	cursor: pointer;
	display: flex;
	height: 40px;
	justify-content: center;
	margin: 0;
	opacity: 0;
	padding: 12px;
	position: fixed;
	right: 20px;
	transform: translateY(30%);
	transition: all 200ms;
	width: 40px;

	z-index: 1000;

	&.active {
		opacity: 1;
	}

	svg {
		height: 16px;
		width: 16px;
	}
}
