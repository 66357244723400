input {
	border: 2px solid #e1e1e1;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 0s 600000s, color 0s 600000s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: rgb(48, 48, 48);
	color: rgb(48, 48, 48);
}