#custom-text {
  padding: 3.125rem;
  margin-bottom: 1.5rem;
  text-align: center;
  background: $white;
  border-radius: 2px;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: $gray-darker;
    text-transform: uppercase;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    color: $gray-darker;

    .dark {
      font-size: 1.125rem;
      font-weight: 600;
      color: $gray-darker;
    }
  }

  img {
    max-width: 100%;
    height: 100%;
  }
}

.page-content.page-cms {
  padding: 0;
  text-align: left;
  background: $white;

  .cms-box {
    img {
      max-width: 100%;
    }
  }

  ul {
    @extend p;
    list-style: disc inside none;
  }


}

@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
}

.product-description {
  p {
    padding-bottom: 1rem;
    margin-bottom: 0;
    color: black !important;
  }

  .text {
    color: unset!important;
    text-shadow: initial!important;
    font-family: Manrope, 'serif' !important;
    font-weight: inherit!important;
    text-transform: inherit!important;
    font-size: initial!important;
}
}